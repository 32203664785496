import gql from 'graphql-tag';
import { apolloClient as apollo } from '@/vue-apollo';
import { PROJECT_CARBON_FIELD } from '@/shared/constants';

export const getLastWasteNumber = async () => {
	try {
		const {
			data: { GetLastWasteNumber }
		} = await apollo.query({
			query: gql`
				query {
					GetLastWasteNumber
				}
			`,
			fetchPolicy: 'no-cache'
		});
		return GetLastWasteNumber;
	} catch (e) {
		console.log({ e });
	}
};

export const getAllDataWasteWithInitCache = async (Filter = {}) => {
	try {
		const {
			data: { GetWastesDetails }
		} = await apollo.query({
			query: gql`
				query ($Filter: AdvancedGlobalFilterInput) {
					GetWastesDetails(Filter: $Filter) {
						id
						createdAt
						updatedAt
						projectId
						wasteId
						reason
						type
						disposalType
						department
						locationId
						userId
						paidByProduction
						kg
						liter
						mTwo
						mThree
						number
						departmentName
						locationName
						kgCoTwo
						images {
							id
							xid
							xidThumb
							ext
							parentType
							parentID
							userID
							originalFileName
							attachToOffer
							attachToOrder
							attachToInvoice
						}
						waste {
							id
							projectId
							userId
							entryDate
							wasteNumber
							note
						}
						location {
							id
							projectId
							type
							type1
							note
						}
						wasteDetailIdUpdate
					}
				}
			`,
			variables: {
				Filter
			},
			fetchPolicy: 'no-cache'
		});
		return GetWastesDetails;
	} catch (e) {
		console.log({ e });
	}
};

export const getNumberPageWasteDetail = async (NumberPerPage) => {
	try {
		const {
			data: { GetNumberPageWasteDetail }
		} = await apollo.query({
			query: gql`
				query ($NumberPerPage: Int!) {
					GetNumberPageWasteDetail(NumberPerPage: $NumberPerPage)
				}
			`,
			variables: {
				NumberPerPage
			},
			fetchPolicy: 'no-cache'
		});
		return GetNumberPageWasteDetail;
	} catch (e) {
		console.log({ e });
	}
};

export const getWasteDetailPercentage = async (reason = 1) => {
	try {
		const {
			data: { GetWasteDetailPercentage }
		} = await apollo.query({
			query: gql`
				query ($reason: Int!) {
					GetWasteDetailPercentage(Reason: $reason) {
						type
						percentage
					}
				}
			`,
			variables: {
				reason: +reason
			},
			fetchPolicy: 'no-cache'
		});
		return GetWasteDetailPercentage;
	} catch (e) {
		console.log({ e });
	}
};

export const getWastesDetailsSlice = async (WasteNumber) => {
	try {
		const {
			data: { GetWastesDetails }
		} = await apollo.query({
			query: gql`
				query ($WasteNumber: Int) {
					GetWastesDetails(WasteNumber: $WasteNumber) {
						id
						createdAt
						updatedAt
						projectId
						wasteId
						reason
						type
						disposalType
						department
						locationId
						userId
						paidByProduction
						kg
						liter
						mTwo
						mThree
						number
						departmentName
						locationName
						kgCoTwo
						images {
							id
							xid
							xidThumb
							ext
							parentType
							parentID
							userID
							originalFileName
							attachToOffer
							attachToOrder
							attachToInvoice
						}
						waste {
							id
							projectId
							userId
							entryDate
							wasteNumber
							note
						}
						location {
							id
							projectId
							type
							type1
							note
						}
						wasteDetailIdUpdate
					}
				}
			`,
			variables: {
				WasteNumber: +WasteNumber
			},
			fetchPolicy: 'no-cache'
		});
		return GetWastesDetails;
	} catch (e) {
		console.error({ e });
	}
};

export const newWasteDetailInit = async () => {
	try {
		const {
			data: { NewWasteDetail }
		} = await apollo.mutate({
			mutation: gql`
				mutation {
					NewWasteDetail
				}
			`,
			fetchPolicy: 'no-cache'
		});
		return NewWasteDetail;
	} catch (e) {
		console.log({ e });
	}
};

export const getAddUpdWaste = async () => {
	try {
		const {
			data: { AddUpdWaste }
		} = await apollo.mutate({
			mutation: gql`
				mutation {
					AddUpdWaste {
						id
						wasteNumber
						entryDate
						createdAt
						note
					}
				}
			`,
			fetchPolicy: 'no-cache'
		});
		return AddUpdWaste;
	} catch (e) {
		console.log({ e });
	}
};

export const addUpdWaste = async ({ WasteId, WasteInput }) => {
	try {
		const mutation = gql`
			mutation ($WasteId: ID, $WasteInput: WasteInput) {
				AddUpdWaste(WasteId: $WasteId, WasteInput: $WasteInput) {
					id
					wasteNumber
				}
			}
		`;
		const { data } = await apollo.mutate({
			mutation,
			variables: {
				WasteId: +WasteId,
				WasteInput: WasteInput
			}
		});
		return data.AddUpdWaste;
	} catch (e) {
		console.error({ e });
	}
};

export const updateWasteUnit = async (WasteUnitInput) => {
	const mutation = gql`
		mutation ($WasteUnitInput: WasteUnitInput!) {
			UpdateWasteUnit(WasteUnitInput: $WasteUnitInput) {
				cardboardUnit
				paperUnit
				plasticUnit
				generalWasteUnit
				foodUnit
				glassUnit
				paintSpecialWasteUnit
				plasticDestructionUnit
				woodUnit
				steelUnit
			}
		}
	`;
	const { data } = await apollo.mutate({
		mutation,
		variables: {
			WasteUnitInput: WasteUnitInput
		}
	});
	return data.UpdateWasteUnit;
};

export const addUpdWasteDetails = async ({ WasteId, WasteDetailsInputs }) => {
	try {
		const mutation = gql`
			mutation ($WasteId: ID!, $WasteDetailsInputs: [WasteDetailInput]!) {
				AddUpdWasteDetails(WasteId: $WasteId, WasteDetailsInputs: $WasteDetailsInputs) {
					id
				}
			}
		`;
		const { data } = await apollo.mutate({
			mutation,
			variables: {
				WasteId: +WasteId,
				WasteDetailsInputs: WasteDetailsInputs
			}
		});
		return data.AddUpdWasteDetails;
	} catch (e) {
		console.error({ e });
	}
};

export const delWasteDetail = async (WasteDetailID) => {
	try {
		const mutation = gql`
			mutation ($WasteDetailID: ID!) {
				DelWasteDetail(WasteDetailID: $WasteDetailID)
			}
		`;
		const { data } = await apollo.mutate({
			mutation,
			variables: {
				WasteDetailID: +WasteDetailID
			}
		});
		return data.DelWasteDetail;
	} catch (e) {
		console.error({ e });
	}
};

export const DelWastesDetailsSlice = async (arrays_id) => {
	try {
		const mutation = gql`
			mutation ($WastesDetailsID: [ID!]!) {
				DelWastesDetails(WastesDetailsID: $WastesDetailsID)
			}
		`;
		const { data } = await apollo.mutate({
			mutation,
			variables: {
				WastesDetailsID: arrays_id
			}
		});
		return data.DelWastesDetails;
	} catch (e) {
		console.error({ e });
	}
};

export const getWasteDetail = async (wasteDetailId) => {
	const query = gql`
    query($wasteDetailId: ID!) {
      GetWasteDetail(WasteDetailId: $wasteDetailId) {
        id
        createdAt
        updatedAt
        projectId
        wasteId
        reason
        type
        disposalType
        department
        locationId
        userId
        paidByProduction
        kg
        liter
        mTwo
        mThree
        number
        departmentName
        locationName
        kgCoTwo
        images {
          id
          xid
          xidThumb
          ext
          parentType
          parentID
          userID
          originalFileName
          attachToOffer
          attachToOrder
          attachToInvoice
        }
        waste {
          id
          projectId
          userId
          entryDate
          wasteNumber
          note
        }
        location {
          id
          projectId
          type
          type1
          note
          setName
        }
        wasteDetailIdUpdate
        carbon {
          ${PROJECT_CARBON_FIELD}
        }
      }
    }
  `;

	const {
		data: { GetWasteDetail }
	} = await apollo.query({
		query,
		variables: {
			wasteDetailId: +wasteDetailId
		},
		fetchPolicy: 'no-cache'
	});

	return GetWasteDetail;
};
